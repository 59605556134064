const ResumeApi = [
    {
      id: 1,
      category: "education",
      year: "University of DVI (1997 - 2001)",
      title: "Personal Portfolio April Fools",
      desc: "The education should be very interactual. Ut tincidunt est ac dolor aliquam sodales. Phasellus sed mauris hendrerit, laoreet sem in, lobortis mauris hendrerit ante.",
      rate: "4.30/5",
    },
    {
      id: 2,
      category: "education",
      year: "College of Studies (2000 - 2002) ",
      title: "Examples Of Personal Portfolio",
      desc: "Maecenas finibus nec sem ut imperdiet. Ut tincidunt est ac dolor aliquam sodales. Phasellus sed mauris hendrerit, laoreet sem in, lobortis mauris hendrerit ante.",
      rate: "4.50/5",
    },
    {
      id: 3,
      category: "education",
      year: "University of Studies (1997 - 2001) ",
      title: "Tips For Personal Portfolio",
      desc: "If you are going to use a passage. Ut tincidunt est ac dolor aliquam sodales. Phasellus sed mauris hendrerit, laoreet sem in, lobortis mauris hendrerit ante.",
      rate: "4.80/5 ",
    },
    {
      id: 4,
      category: "experience",
      year: "NAVTTC (Jun 2021 - Dec 2021)",
      title: "ARTIFICIAL INTELLIGENCE (ML/DL)",
      desc: "Artificial intelligence is the simulation of human intelligence processes by machines, especially computer systems. Specific applications of AI include expert systems, natural language processing, speech recognition and machine vision.",
      rate: "3.89/4 ",
    },
    
  ]
  
  export default ResumeApi