import img1 from "../images/pic-1.jpg"
import img2 from "../images/pic-2.jpg"
import img3 from "../images/pic-3.jpg"
import img4 from "../images/pic-4.jpg"
import img5 from "../images/pic-5.jpg"
import img6 from "../images/pic-6.jpg"


const Portfolio_data = [
    {
      id: 1,
      category: "DEVELOPMENT",
      totalLike: "600",
      title: "The services provide for design ",
      image: img1 ,
    },
    {
      id: 2,
      category: "APPLICATION",
      totalLike: "750",
      title: "Mobile app landing design & maintain",
      image: img2,
    },
    {
      id: 3,
      category: "PHOTOSHOP",
      totalLike: "630",
      title: "Logo design creativity & Application ",
      image: img3,
    },
    {
      id: 4,
      category: "FIGMA",
      totalLike: "360",
      title: "Mobile app landing design & Services",
      image: img4,
    },
    {
      id: 5,
      category: "WEB DESIGN",
      totalLike: "280",
      title: "Design for tecnology & services",
      image: img5,
    },
    {
      id: 6,
      category: "WEB DESIGN",
      totalLike: "690",
      title: "App for tecnology & services",
      image: img6,
    },
  ]
  export default Portfolio_data