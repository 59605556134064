import React from "react"

const Footer = () => {
  return (
    <>
      <footer>
        <div className='conatiner text-center top'>
          <div className='img'>
            <h2>BitTec</h2>
          </div>
          <p>© 2021. All rights reserved by BitTec.</p>
        </div>
      </footer>
    </>
  )
}

export default Footer