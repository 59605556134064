import './App.css';
import Features from './components/features/Features';
import Header from './components/header/Header';
import Home from './components/home/Home';
import Portfolio from './components/portfolio/Portfolio';
import Resume from './components/resume/Resume';
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer';

function App() {
  return (
    <>
    <Header />
    <Home />
    <Features />
    <Portfolio />
    <Resume />
    <Contact />
    <Footer />
    </>

  );
}

export default App;
