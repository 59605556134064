import React from 'react';
import Card from '../resume/Card';
import ResumeApi from './ResumeAPI';
import "./resume.css"

const Resume = () => {
    return (
      <>
        <section className='Resume' id='resume'>
          <div className='container top'>
            <div className='heading text-center'>
              
              <h1>My Resume</h1>
            </div>
  
            <div className='content-section mtop d_flex'>
              <div className='left'>
                <div className='heading'>
                  <h4>2018-2022</h4>
                  <h1>Education Quality</h1>
                </div>
  
                <div className='content'>
                  {/* {ResumeApi.map((val, id) => {
                    if (val.category === "education") {
                      return <Card key={id} title={val.title} year={val.year} desc={val.desc} />
                    }
                  })} */}
  
                  <div className='box btn_shadow'>
                    <div className='title_content d_flex'>
                      <div className='title'>
                        <h2>BACHELOR OF SCIENCE SOFTWARE ENGINEERING</h2>
                        <span> (2018 - 2022)</span>
                      </div>
                      <div className='rate'>
                        <button className='btn_shadow '>3/4</button>
                      </div>
                    </div>
                    <hr />
                    <p> What is in Bachelor of Computer Science?
Image result for bachelor of computer science
Bachelor of computer science (BCS) courses vary by school and program, but they typically cover areas like computer programming, software engineering, computer hardware, and artificial intelligence engineering. </p>
                  </div>
                 
                </div>
              </div>
              <div className='left'>
                <div className='heading'>
                  <h4>2021-2021</h4>
                  <h1>Certificates</h1>
                </div>
  
                <div className='content'>
                  {ResumeApi.map((val, index) => {
                    if (val.category === "experience") {
                      return <Card key={index} title={val.title} year={val.year}  desc={val.desc} />
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

export default Resume